import React from "react";
import Header from "src/components/Header";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <Header></Header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/diarree-op-reis/"> Diarree op reis </a>
              </li>
              <li className="active">
                <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                  Reizigersdiarree vermijden
                </a>
              </li>
              <li>
                <a href="/diarree-op-reis/behandeling-van-reizigersdiarree/">
                  Reizigersdiarree behandelen
                </a>
              </li>
              <li className="last">
                <a href="/diarree-op-reis/reisapotheek/">Reisapotheek</a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Reisapotheek</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Reisapotheek"
                  />
                </div>
                <p>
                  Wat hoort thuis in de <strong>reisapotheek</strong>? Vind hier
                  alle praktische informatie.
                </p>
                <a href="/diarree-op-reis/reisapotheek/">Meer informatie</a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>Tips bij diarree</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_durchfallmythen.jpeg"
                    width="170"
                    height="102"
                    alt="Tips bij diarree"
                  />
                </div>
                <p>
                  Rond het behandelen van de symptomen van diarree bestaan er
                  nog vele misverstanden en&nbsp;mythes. Hier vind je enkele
                  tips over het behandelen van de symptomen van diarree.
                </p>
                <a href="/feiten-over-diarree/tips-voor-de-behandeling-van-diarree/">
                  Tips bij diarree
                </a>
              </div>
            </div>
            <div className="widget">
              <div className="inner">
                <h4>IMODIUM® Instant Smelttabletten</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/Imodium-Smelttablet-2mg-3D-Frontal-NL.png"
                    width="170"
                    height="102"
                    alt="IMODIUM® Instant Smelttabletten"
                  />
                </div>
                <p>
                  Handige hulp bij diarree. Inname zonder water en zonder
                  slikken. Smelt in enkele&nbsp;seconden op de tong.
                </p>
                <a href="/imodium-behandelen-diarree/imodium-instant/">
                  Meer informatie
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-129">
            <h1>Reizigersdiarree vermijden</h1>
            <div className="img">
              <img
                src="/assets/files/pages/reisen.jpeg"
                width="701"
                height="289"
                alt="Reizigersdiarree vermijden"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Hoe kan je reizigers-diarree vermijden?</h2>
              </div>
              <div className="nine columns">
                <p>
                  Je kent het: je verheugt je maandenlang op een welverdiende
                  vakantie en vanaf een van de eerste dagen zit je op het
                  toilet. <strong>Reizigersdiarree</strong> wordt meestal
                  veroorzaakt door inname van verontreinigd eten of water.
                </p>
                <p>
                  Ook kunnen vettig of sterk gekruid eten en overmatige
                  alcoholinname diarree uitlokken. In vele gevallen kunnen de
                  symptomen behandeld worden met middelen tegen diarree zoals
                  IMODIUM®. De smelttabletten kan je zonder water innemen, zodat
                  het een gemakkelijke oplossing is om diarree snel te doen
                  stoppen tijdens je vakantie.
                </p>
              </div>
            </div>
            <h4>
              Hieronder vind je enkele nuttig tips om deze ellende op reis te
              voorkomen.
            </h4>
            <p>
              <strong>Tips</strong> voor het vermijden van verontreinigd water:
            </p>
            <ul>
              <li>
                Drink geen kraanwater en poets er ook je tanden niet mee. Drink
                of gebruik liever water uit verzegelde flessen of gekookt water.
                Vermijd ook ijsklontjes die met kraanwater worden gemaakt.
              </li>
              <li>
                Je kan ook andere dranken uit flessen of pakjes drinken. Zeker
                in verre uitheemse gebieden moet je er zeker van zijn dat de
                fles niet geopend is voor ze aan tafel wordt gebracht.
              </li>
              <li>
                Houd bij het zwemmen het hoofd boven water en probeer geen water
                door te slikken, want het kan verontreinigd zijn.
              </li>
              <li>
                Zorg ook bij het baden of douchen dat je geen water binnen
                krijgt. Zingen onder de douche doe je dus beter even niet!
              </li>
              <li>
                Heb je toch pech en wordt je reis verpest door diarree? Volg dan
                volgende richtlijnen om reizigersdiarree te behandelen.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>Tips</strong> voor het vermijden van verontreinigde
              voedingsmiddelen:
            </p>
            <ul>
              <li>
                Let op dat de gerechten goed gekookt zijn en zo heet zijn als ze
                moeten zijn.
              </li>
              <li>
                Let er zoveel mogelijk op dat etensresten afgedekt of in de
                koelkast bewaard worden.
              </li>
              <li>Was voor het eten grondig je handen met zeep.</li>
            </ul>
            <p>&nbsp;</p>
            <p>
              Probeer het volgende te <strong>vermijden</strong>:
            </p>
            <ul>
              <li>
                Voedingsmiddelen die mogelijk met kraanwater gewassen werden,
                zoals vruchten (schil ze voor de veiligheid liever), salades en
                rauwe groenten;&nbsp;
              </li>
              <li>Roomijs van onbetrouwbare herkomst;</li>
              <li>Snacks van straatverkopers;</li>
              <li>Koud vlees;</li>
              <li>Schaaldieren of rauwe zeevruchten;</li>
              <li>Niet gepasteuriseerde melk (bij twijfel laten koken);</li>
              <li>Opgewarmd eten.</li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <div
            className="flash-wrapper"
            style={{
              "max-width": "100%",
              height: "236px",
              width: "428px",
              margin: "0 auto"
            }}
          >
            <div id="flash">
              <p>
                Your browser must have Adobe Flash installed to see this
                animation.
              </p>
            </div>
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="nl-BE" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
         <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script src="/assets/js/custom.js" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
         
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\ncookiesDirective('bottom',5,'/cookiebeleid');\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
